const REGIONS = [
  {
    name: "Aachen",
    slug: "aachen",
    localization: {
      en: "Aachen",
      de: "Aachen",
    },
    center: [6.0816192626953125, 50.77772108971944],
    disabled: false,
  },
  {
    name: "1st Arrondissement",
    slug: "1st-arrondissement-of-paris",
    localization: {
      en: "1st Arrondissement",
      de: "1. Arrondissement",
    },
    center: [2.3364433620533913, 48.862562701836076],
    disabled: false,
  },
  {
    name: "2nd Arrondissement",
    slug: "2nd-arrondissement-of-paris",
    localization: {
      en: "2nd Arrondissement",
      de: "2. Arrondissement",
    },
    center: [2.342802546891387, 48.86827922252253],
    disabled: false,
  },
  {
    name: "3rd Arrondissement",
    slug: "3rd-arrondissement-of-paris",
    localization: {
      en: "3rd Arrondissement",
      de: "3. Arrondissement",
    },
    center: [2.360000985897693, 48.86287238001695],
    disabled: false,
  },
  {
    name: "4th Arrondissement",
    slug: "4th-arrondissement-of-paris",
    localization: {
      en: "4th Arrondissement",
      de: "4. Arrondissement",
    },
    center: [2.357629620324992, 48.85434142627287],
    disabled: false,
  },
  {
    name: "5th Arrondissement",
    slug: "5th-arrondissement-of-paris",
    localization: {
      en: "5th Arrondissement",
      de: "5. Arrondissement",
    },
    center: [2.3507146095752547, 48.8444431505327],
    disabled: false,
  },
  {
    name: "6th Arrondissement",
    slug: "6th-arrondissement-of-paris",
    localization: {
      en: "6th Arrondissement",
      de: "6. Arrondissement",
    },
    center: [2.332897999053315, 48.84913035858523],
    disabled: false,
  },
  {
    name: "7th Arrondissement",
    slug: "7th-arrondissement-of-paris",
    localization: {
      en: "7th Arrondissement",
      de: "7. Arrondissement",
    },
    center: [2.312187691482017, 48.85617442877938],
    disabled: false,
  },
  {
    name: "8th Arrondissement",
    slug: "8th-arrondissement-of-paris",
    localization: {
      en: "8th Arrondissement",
      de: "8. Arrondissement",
    },
    center: [2.3125540224020646, 48.872720837434535],
    disabled: false,
  },
  {
    name: "9th Arrondissement",
    slug: "9th-arrondissement-of-paris",
    localization: {
      en: "9th Arrondissement",
      de: "9. Arrondissement",
    },
    center: [2.3374575434825435, 48.877163517328896],
    disabled: false,
  },
  {
    name: "10th Arrondissement",
    slug: "10th-arrondissement-of-paris",
    localization: {
      en: "10th Arrondissement",
      de: "10. Arrondissement",
    },
    center: [2.3607284878474473, 48.876130036539095],
    disabled: false,
  },
  {
    name: "11th Arrondissement",
    slug: "11th-arrondissement-of-paris",
    localization: {
      en: "11th Arrondissement",
      de: "11. Arrondissement",
    },
    center: [2.380058308197898, 48.85905922134252],
    disabled: false,
  },
  {
    name: "12th Arrondissement",
    slug: "12th-arrondissement-of-paris",
    localization: {
      en: "12th Arrondissement",
      de: "12. Arrondissement",
    },
    center: [2.421324900784678, 48.8349743814805],
    disabled: false,
  },
  {
    name: "13th Arrondissement",
    slug: "13th-arrondissement-of-paris",
    localization: {
      en: "13th Arrondissement",
      de: "13. Arrondissement",
    },
    center: [2.362272440420906, 48.82838803174469],
    disabled: false,
  },
  {
    name: "14th Arrondissement",
    slug: "14th-arrondissement-of-paris",
    localization: {
      en: "14th Arrondissement",
      de: "14. Arrondissement",
    },
    center: [2.32654204419894, 48.829244500489835],
    disabled: false,
  },
  {
    name: "15th Arrondissement",
    slug: "15th-arrondissement-of-paris",
    localization: {
      en: "15th Arrondissement",
      de: "15. Arrondissement",
    },
    center: [2.2928258224249953, 48.840085375938166],
    disabled: false,
  },
  {
    name: "16th Arrondissement",
    slug: "16th-arrondissement-of-paris",
    localization: {
      en: "16th Arrondissement",
      de: "16. Arrondissement",
    },
    center: [2.261970788364543, 48.860392105415855],
    disabled: false,
  },
  {
    name: "17th Arrondissement",
    slug: "17th-arrondissement-of-paris",
    localization: {
      en: "17th Arrondissement",
      de: "17. Arrondissement",
    },
    center: [2.306776990574396, 48.8873265220258],
    disabled: false,
  },
  {
    name: "18th Arrondissement",
    slug: "18th-arrondissement-of-paris",
    localization: {
      en: "18th Arrondissement",
      de: "18. Arrondissement",
    },
    center: [2.348160519562042, 48.892569268005815],
    disabled: false,
  },
  {
    name: "19th Arrondissement",
    slug: "19th-arrondissement-of-paris",
    localization: {
      en: "19th Arrondissement",
      de: "19. Arrondissement",
    },
    center: [2.384820960152521, 48.887075996572506],
    disabled: false,
  },
  {
    name: "20th Arrondissement",
    slug: "20th-arrondissement-of-paris",
    localization: {
      en: "20th Arrondissement",
      de: "20. Arrondissement",
    },
    center: [2.4011881292846833, 48.86346057889559],
    disabled: false,
  },
  {
    name: "Augsburg",
    slug: "augsburg",
    localization: {
      en: "Augsburg",
      de: "Augsburg",
    },
    disabled: false,
    center: [10.903244018554688, 48.35612739009784],
  },
  {
    name: "Bayreuth",
    slug: "bayreuth",
    localization: {
      en: "Bayreuth",
      de: "Bayreuth",
    },
    disabled: false,
    center: [11.582164764404297, 49.941467079225475],
  },
  {
    name: "Bergisch Gladbach",
    slug: "bergischgladbach",
    localization: {
      en: "Bergisch Gladbach",
      de: "Bergisch Gladbach",
    },
    center: [7.127807216543886, 50.99285895167259],
    disabled: false,
  },
  {
    name: "Berlin",
    slug: "berlin",
    localization: {
      en: "Berlin",
      de: "Berlin",
    },
    center: [13.4045, 52.5172],
    disabled: false,
  },
  {
    name: "Bielefeld",
    slug: "bielefeld",
    localization: {
      en: "Bielefeld",
      de: "Bielefeld",
    },
    center: [8.531007204861993, 52.01907801249715],
    disabled: false,
  },
  {
    name: "Bochum",
    slug: "bochum",
    localization: {
      en: "Bochum",
      de: "Bochum",
    },
    disabled: false,
    center: [7.223167419433594, 51.480595117587285],
  },
  {
    name: "Bonn",
    slug: "bonn",
    localization: {
      en: "Bonn",
      de: "Bonn",
    },
    center: [7.117572, 50.719114],
    disabled: false,
  },
  {
    name: "Braunschweig",
    slug: "brunswick",
    localization: {
      en: "Brunswick",
      de: "Braunschweig",
    },
    disabled: false,
    center: [10.534515380859375, 52.252907178055935],
  },
  {
    name: "Bremen",
    slug: "bremen",
    localization: {
      en: "Bremen",
      de: "Bremen",
    },
    center: [8.8071646, 53.0758196],
    disabled: false,
  },
  {
    name: "Köln",
    slug: "cologne",
    localization: {
      en: "Cologne",
      de: "Köln",
    },
    center: [6.95495255522418, 50.9577031],
    disabled: false,
  },
  {
    name: "Darmstadt",
    slug: "darmstadt",
    localization: {
      en: "Darmstadt",
      de: "Darmstadt",
    },
    center: [8.6511929, 49.8728253],
    disabled: false,
  },
  {
    name: "Dortmund",
    slug: "dortmund",
    localization: {
      en: "Dortmund",
      de: "Dortmund",
    },
    center: [7.4652789, 51.5142273],
    disabled: false,
  },
  {
    name: "Dresden",
    slug: "dresden",
    localization: {
      en: "Dresden",
      de: "Dresden",
    },
    center: [13.7373, 51.0504],
    disabled: false,
  },
  {
    name: "Duisburg",
    slug: "duisburg",
    localization: {
      en: "Duisburg",
      de: "Duisburg",
    },
    disabled: false,
    center: [6.769294738769531, 51.42563880852481],
  },
  {
    name: "Düsseldorf",
    slug: "dusseldorf",
    localization: {
      en: "Dusseldorf",
      de: "Düsseldorf",
    },
    center: [6.7763137, 51.2254018],
    disabled: false,
  },
  {
    name: "Erlangen",
    slug: "erlangen",
    localization: {
      en: "Erlangen",
      de: "Erlangen",
    },
    center: [11.003653, 49.598121],
    disabled: false,
  },
  {
    name: "Essen",
    slug: "essen",
    localization: {
      en: "Essen",
      de: "Essen",
    },
    center: [7.012023925781249, 51.430895644580175],
    disabled: false,
  },
  {
    name: "Esslingen",
    slug: "esslingen",
    localization: {
      en: "Esslingen",
      de: "Esslingen",
    },
    center: [9.30716896233443, 48.74275485248148],
    disabled: false,
  },
  {
    name: "Frankfurt",
    slug: "frankfurt",
    localization: {
      en: "Frankfurt",
      de: "Frankfurt",
    },
    center: [8.6620096369594, 50.1213881],
    disabled: false,
  },
  {
    name: "Freiberg",
    slug: "freiberg",
    localization: {
      en: "Freiberg",
      de: "Freiberg",
    },
    disabled: false,
    center: [13.341522216796875, 50.91113368259141],
  },
  {
    name: "Freiburg",
    slug: "Freiburg",
    localization: {
      en: "Freiburg",
      de: "Freiburg",
    },
    disabled: false,
    center: [7.836685180664062, 47.99635490025392],
  },
  {
    name: "Gelsenkirchen",
    slug: "gelsenkirchen",
    localization: {
      en: "Gelsenkirchen",
      de: "Gelsenkirchen",
    },
    disabled: false,
    center: [7.100944519042969, 51.51063905291758],
  },
  {
    name: "Hagen",
    slug: "hagen",
    localization: {
      en: "Hagen",
      de: "Hagen",
    },
    center: [7.473298146575615, 51.35829133853014],
    disabled: false,
  },
  {
    name: "Hamburg",
    slug: "hamburg",
    localization: {
      en: "Hamburg",
      de: "Hamburg",
    },
    center: [10.000654, 53.5503414],
    disabled: false,
  },
  {
    name: "Hannover",
    slug: "hanover",
    localization: {
      en: "Hanover",
      de: "Hannover",
    },
    center: [9.716667, 52.366667],
    disabled: false,
  },
  {
    name: "Heidelberg",
    slug: "heidelberg",
    localization: {
      en: "Heidelberg",
      de: "Heidelberg",
    },
    center: [8.686408996582031, 49.40935369938753],
    disabled: false,
  },
  {
    name: "Ingolstadt",
    slug: "ingolstadt",
    localization: {
      en: "Ingolstadt",
      de: "Ingolstadt",
    },
    center: [11.4250395, 48.7630165],
    disabled: false,
  },
  {
    name: "Kaiserslautern",
    slug: "kaiserslautern",
    localization: {
      en: "Kaiserslautern",
      de: "Kaiserslautern",
    },
    disabled: false,
    center: [7.768979859326379, 49.442835197542735],
  },
  {
    name: "Karlsruhe",
    slug: "karlsruhe",
    localization: {
      en: "Karlsruhe",
      de: "Karlsruhe",
    },
    disabled: false,
    center: [8.401966094970701, 49.00556010160893],
  },
  {
    name: "Kassel",
    slug: "kassel",
    localization: {
      en: "Kassel",
      de: "Kassel",
    },
    disabled: false,
    center: [9.492409154813743, 51.31543476192323],
  },
  {
    name: "Kiel",
    slug: "kiel",
    localization: {
      en: "Kiel",
      de: "Kiel",
    },
    disabled: false,
    center: [10.165786743164062, 54.32195242906216],
  },
  {
    name: "Koblenz",
    slug: "koblenz",
    localization: {
      en: "Koblenz",
      de: "Koblenz",
    },
    disabled: false,
    center: [7.5943959688752045, 50.35308779638049],
  },
  {
    name: "Landshut",
    slug: "landshut",
    localization: {
      en: "Landshut",
      de: "Landshut",
    },
    center: [12.147359848022461, 48.53581752639674],
    disabled: false,
  },
  {
    name: "Leipzig",
    slug: "leipzig",
    localization: {
      en: "Leipzig",
      de: "Leipzig",
    },
    center: [12.3731, 51.3397],
    disabled: false,
  },
  {
    name: "Leverkusen",
    slug: "leverkusen",
    localization: {
      en: "Leverkusen",
      de: "Leverkusen",
    },
    center: [6.975197929252317, 51.0311182269146],
    disabled: false,
  },
  {
    name: "Ludwigshafen",
    slug: "ludwigshafen",
    localization: {
      en: "Ludwigshafen",
      de: "Ludwigshafen",
    },
    center: [8.39006577268274, 49.487297],
    disabled: false,
  },
  {
    name: "Mainz",
    slug: "mainz",
    localization: {
      en: "Mainz",
      de: "Mainz",
    },
    disabled: false,
    center: [8.270988464355469, 49.99534689352992],
  },
  {
    name: "Mannheim",
    slug: "mannheim",
    localization: {
      en: "Mannheim",
      de: "Mannheim",
    },
    disabled: false,
    center: [8.449859619140625, 49.48340102843969],
  },
  {
    name: "Mönchengladbach",
    slug: "monchengladbach",
    localization: {
      en: "Monchengladbach",
      de: "Mönchengladbach",
    },
    disabled: false,
    center: [6.4469146728515625, 51.187829525237134],
  },
  {
    name: "Mülheim",
    slug: "mulheim",
    localization: {
      en: "Mulheim",
      de: "Mülheim",
    },
    center: [6.882922595033563, 51.427242907853525],
    disabled: false,
  },
  {
    name: "München",
    slug: "munich",
    localization: {
      en: "Munich",
      de: "München",
    },
    center: [11.582, 48.1351],
    disabled: false,
  },
  {
    name: "Münster",
    slug: "munster",
    localization: {
      en: "Munster",
      de: "Münster",
    },
    disabled: false,
    center: [7.628116607666016, 51.956804035015],
  },
  {
    name: "Nürnberg",
    slug: "nuremberg",
    localization: {
      en: "Nuremberg",
      de: "Nürnberg",
    },
    center: [11.077298, 49.453872],
    disabled: false,
  },
  {
    name: "Osnabrück",
    slug: "osnabruck",
    localization: {
      en: "Osnabruck",
      de: "Osnabrück",
    },
    center: [8.047639120713484, 52.27195049050775],
    disabled: false,
  },
  {
    name: "Potsdam",
    slug: "potsdam",
    localization: {
      en: "Potsdam",
      de: "Potsdam",
    },
    disabled: false,
    center: [13.063945770263672, 52.39239653526932],
  },
  {
    name: "Ratingen",
    slug: "ratingen",
    localization: {
      en: "Ratingen",
      de: "Ratingen",
    },
    disabled: false,
    center: [6.849364018842579, 51.29727739091959],
  },
  {
    name: "Regensburg",
    slug: "regensburg",
    localization: {
      en: "Regensburg",
      de: "Regensburg",
    },
    center: [12.0974869, 49.0195333],
    disabled: false,
  },
  {
    name: "Rostock",
    slug: "rostock",
    localization: {
      en: "Rostock",
      de: "Rostock",
    },
    disabled: false,
    center: [12.131309509277344, 54.08707652523681],
  },
  {
    name: "Stuttgart",
    slug: "stuttgart",
    localization: {
      en: "Stuttgart",
      de: "Stuttgart",
    },
    center: [9.1800132, 48.7784485],
    disabled: false,
  },
  {
    name: "Solingen",
    slug: "solingen",
    localization: {
      en: "Solingen",
      de: "Solingen",
    },
    center: [7.08390212130422, 51.17111224679394],
    disabled: false,
  },
  {
    name: "Ulm",
    slug: "ulm",
    localization: {
      en: "Ulm",
      de: "Ulm",
    },
    center: [9.996185302734375, 48.39273786659243],
    disabled: false,
  },
  {
    name: "Wiesbaden",
    slug: "wiesbaden",
    localization: {
      en: "Wiesbaden",
      de: "Wiesbaden",
    },
    disabled: false,
    center: [8.243522644042969, 50.07286726563162],
  },
  {
    name: "Wolfsburg",
    slug: "wolfsburg",
    localization: {
      en: "Wolfsburg",
      de: "Wolfsburg",
    },
    center: [10.7861682, 52.4205588],
    disabled: false,
  },
  {
    name: "Worms",
    slug: "worms",
    localization: {
      en: "Worms",
      de: "Worms",
    },
    disabled: false,
    center: [8.362655639648438, 49.629269578423646],
  },
  {
    name: "Wuppertal",
    slug: "wuppertal",
    localization: {
      en: "Wuppertal",
      de: "Wuppertal",
    },
    disabled: false,
    center: [7.176475524902344, 51.25580001251798],
  },
];

export default REGIONS;
