import { sanitizeUrl } from "@braintree/sanitize-url";
import { languages } from "@wunderflats/constants";
import pathToRegexp from "path-to-regexp";
import qs from "qs";
import { getRoute, regionSearchPageRoutesMapper } from "../routes";
import WF_CONFIG from "./WF_CONFIG";

const { USER_PROFILE_LANGUAGE } = languages;

// eslint-disable-next-line new-cap
const { HTTPS, LANGUAGE_HOST_EN } = WF_CONFIG();
class MissingLanguageParamError extends Error {
  constructor() {
    super();
    this.name = "MissingLanguageParamError";
    this.message = "Lang parameter is undefined";
  }
}

export function url(name, routeParams = {}, options) {
  if (!routeParams.lang) {
    throw new MissingLanguageParamError();
  }

  for (const paramKey of Object.keys(routeParams)) {
    if (routeParams[paramKey] && typeof routeParams[paramKey] !== "string") {
      routeParams[paramKey] = String(routeParams[paramKey]);
    }
  }

  try {
    const route = getRoute(name, routeParams.lang);

    let finalUrl = pathToRegexp.compile(route)(
      regionSearchPageRoutesMapper(name, routeParams),
    );

    if (options.query) {
      const queryString = qs.stringify(options.query);

      if (queryString.length > 0) {
        finalUrl = `${finalUrl}?${queryString}`;
      }
    }

    if (options.hash) {
      // If the 'hash' option is provided, its value must start with a "#".
      // This is analogous to how window.location.hash works:
      if (!options.hash.startsWith("#")) {
        throw new Error("url: options.hash must start with a hash ('#')");
      }

      finalUrl += options.hash;
    }

    return sanitizeUrl(finalUrl);
  } catch (err) {
    return undefined;
  }
}

export const relativeToFullyQualified = (relativeUrl) => {
  if (!relativeUrl) return relativeUrl;
  return `${
    HTTPS === "true" ? "https://" : "http://"
  }${LANGUAGE_HOST_EN}${relativeUrl}`;
};

const defaultOptions = {
  query: {},
};

export const urlMaker =
  (lang, { fullyQualified } = { fullyQualified: false }) =>
  (name, params = {}, optionsObject) => {
    const options =
      typeof optionsObject === "object" ? optionsObject : defaultOptions;

    // params.lang is overriding the lang
    const urlFuncParams = { lang, ...params };

    if (fullyQualified) {
      return relativeToFullyQualified(url(name, urlFuncParams, options));
    }

    return url(name, urlFuncParams, options);
  };

/**
 * Checks whether the specified page is the current page.
 *
 * @param {String} currentUrl
 * @param {Object|String} route - Check `src/routes.js`
 *
 * @example
 * isCurrentPage("https://wunderflats.com/en/wishlist", "shortlist");
 *
 * @example
 * isCurrentPage("https://wunderflats.com/en/furnished-apartments/berlin", { name: "listinglist", lang: "en" })
 *
 * @returns {Boolean}
 */
export const isCurrentPage = (currentUrl, route) => {
  if (!currentUrl || !route) {
    return false;
  }

  let currentRegexpPath;

  try {
    currentRegexpPath =
      typeof route === "object"
        ? getRoute(route.name, route.lang)
        : getRoute(route);
  } catch (error) {
    return false;
  }

  // currentUrl might be a relative path
  // In this case we shouldn't parse it in order to get pathname
  let pathname = "";
  try {
    const url = new URL(currentUrl);
    pathname = url.pathname;
  } catch (error) {
    pathname = currentUrl;
  }

  const regexp = pathToRegexp(currentRegexpPath);

  return regexp.exec(pathname) !== null;
};

export const safeDecodeComponent = (str) => {
  let result;
  try {
    result = decodeURIComponent(str);
  } catch (e) {
    console.info(
      "Tried to use `decodeURIComponent` but it returned error, using `unescape` now",
    );

    if (unescape != null && unescape instanceof Function) {
      result = unescape(str);
    }
  }

  return result;
};

export function getHelpRoute(params) {
  if (params.postId) return "helpSinglePost";
  if (params.subcategoryId) return "helpSubCategory";
  if (params.categoryId) return "helpCategory";

  return "help";
}

export const shouldAddLanguageToCategory = (lang) =>
  lang === USER_PROFILE_LANGUAGE.uk || lang === USER_PROFILE_LANGUAGE.ru;

export const getRefugeeFocusedCategoryUrl = ({ lang, query }) => {
  const url = urlMaker(lang);

  const computedLang =
    lang === USER_PROFILE_LANGUAGE.fr ? USER_PROFILE_LANGUAGE.en : lang;
  let computedCategory =
    lang !== USER_PROFILE_LANGUAGE.de
      ? "homes-for-ukrainians"
      : "unterkunfte-fur-ukrainer";

  if (shouldAddLanguageToCategory(lang)) {
    computedCategory = `${computedCategory}-${lang}`;
  }

  return url(
    "categories",
    {
      lang: computedLang,
      region: "germany",
      category: computedCategory,
    },
    { query: { showPlaceOfResidenceModal: true, ...query } },
  );
};

export const getComputedListingsUrl = ({
  hasRefugeeLR = false,
  lang,
  query,
  region,
}) => {
  const url = urlMaker(lang);

  return hasRefugeeLR
    ? getRefugeeFocusedCategoryUrl({ lang, query })
    : url("listinglist", { region }, { query });
};
