/* eslint-disable react/prop-types */
import React from "react";
import escapeHtml from "escape-html";
import { Helmet } from "react-helmet";
import jsonStringifySafe from "../../../utils/json-stringify-safe";

const getRobotsTag = ({ index, follow, noFollow } = {}) => {
  const content = [];

  if (index) {
    content.push("index");
  } else if (!index) {
    content.push("noindex");
  }

  if (follow) {
    content.push("follow");
  }

  if (noFollow) {
    content.push("nofollow");
  }

  return content.length === 0 ? null : (
    <meta name="robots" content={content.join(",")} />
  );
};

// Locales that shouldn't be indexed by search engines
export const NO_INDEX_LOCALES = ["uk", "ru"];

const getHreflangTags = ({
  index,
  lang,
  canonicalUrl,
  hasCategory,
  hreflang,
  translationUrls,
}) => {
  // Pages that aren't indexed shouldn't have hreflang tags
  if (!index) {
    return null;
  }

  const hreflangTags = [];
  if (hreflang) {
    hreflangTags.push(
      <link key={lang} rel="alternate" hrefLang={lang} href={canonicalUrl} />,
    );
    Object.keys(hreflang).forEach((langCode) => {
      if (!NO_INDEX_LOCALES.includes(langCode)) {
        hreflangTags.push(
          <link
            key={langCode}
            rel="alternate"
            hrefLang={langCode}
            href={hreflang[langCode]}
          />,
        );
      }
    });
  } else if (hasCategory) {
    hreflangTags.push(
      <link key={lang} rel="alternate" hrefLang={lang} href={canonicalUrl} />,
    );
  } else {
    Object.keys(translationUrls).forEach((langCode) => {
      if (!NO_INDEX_LOCALES.includes(langCode)) {
        hreflangTags.push(
          <link
            key={langCode}
            rel="alternate"
            hrefLang={langCode}
            href={translationUrls[langCode]}
          />,
        );
      }
    });
  }

  return hreflangTags;
};

const PageHead = ({
  title = "page",
  description,
  canonicalUrl,
  prev,
  next,
  translationUrls = {},
  ogImage = "https://wunderflats.com/public/images/fb_og_preview.png",
  noIndex,
  follow,
  structuredData,
  ogTitle,
  children,
  noFollow,
  lang,
  hasCategory,
  hreflang,
}) => {
  // Disable indexing for Ukrainian & Russian pages
  const index = !noIndex && !NO_INDEX_LOCALES.includes(lang);

  const hreflangTags = getHreflangTags({
    index,
    lang,
    canonicalUrl,
    hasCategory,
    hreflang,
    translationUrls,
  });

  return (
    <Helmet>
      <title>{title}</title>
      <meta
        property="og:title"
        content={`${escapeHtml(ogTitle || title)} - Wunderflats`}
      />
      {description && (
        <meta name="description" content={escapeHtml(description)} />
      )}
      {description && (
        <meta property="og:description" content={escapeHtml(description)} />
      )}
      {canonicalUrl && index && (
        <link
          data-testid="canonicalUrl"
          rel="canonical"
          href={`${escapeHtml(canonicalUrl)}`}
        />
      )}
      {canonicalUrl && (
        <meta property="og:url" content={escapeHtml(canonicalUrl)} />
      )}
      {prev && <link rel="prev" href={escapeHtml(prev)} />}
      {next && <link rel="next" href={escapeHtml(next)} />}
      {ogImage && <meta property="og:image" content={escapeHtml(ogImage)} />}
      {hreflangTags && hreflangTags.map((tag) => tag)}
      {getRobotsTag({ index, follow, noFollow })}
      {structuredData && (
        <script type="application/ld+json">
          {jsonStringifySafe(structuredData)}
        </script>
      )}
      {children}
    </Helmet>
  );
};

export default PageHead;
