/** It's important for SEO purposes to have URL slugs that match the language of the page.
 * If the page is in French, then ideally the URL slugs should be in French as well.
 * This also applies to the region names. The names of counties, cities, and regions
 * sometimes sound different in different languages. This file containts functions that map
 * locale-specific versions of region names to the English version of the region name.
 * The English version is used on the backend to filter listings.
 */

// Both versions of handling umlauts are used, so we need to map them both to the same region name
const UMLAUT_ALTERNATIVE_FORMS = {
  koln: "koeln",
  dusseldorf: "duesseldorf",
  gottingen: "goettingen",
};

/** Transforms region name to lowercase and replaces umlauts with alternative spelling. */
function normalizeRegionName(regionName) {
  const lowercaseRegionName = regionName.toLowerCase();

  if (UMLAUT_ALTERNATIVE_FORMS[lowercaseRegionName]) {
    return UMLAUT_ALTERNATIVE_FORMS[lowercaseRegionName];
  }

  // umlaut replacements
  return lowercaseRegionName
    .replace(/\u00df/g, "ss")
    .replace(/\u00e4/g, "ae")
    .replace(/\u00f6/g, "oe")
    .replace(/\u00fc/g, "ue");
}

/** Contains region names in all locales we use (for regions that have different names in different languages). */
const LOCALISED_REGION_NAMES = [
  { de: "deutschland", en: "germany", fr: "allemagne" },
  { de: "duesseldorf", en: "dusseldorf", fr: "dusseldorf" },
  { de: "goettingen", en: "gottingen", fr: "gottingen" },
  { de: "braunschweig", en: "brunswick", fr: "brunswick" },
  { de: "koeln", en: "cologne", fr: "cologne" },
  { de: "hannover", en: "hanover", fr: "hanover" },
  { de: "muenchen", en: "munich", fr: "munich" },
  { de: "muenster", en: "munster", fr: "munster" },
  { de: "nurnberg", en: "nuremberg", fr: "fruremberg" },
  { de: "wien", en: "vienna", fr: "vienna" },
  {
    de: "1-arrondissement-von-paris",
    en: "1st-arrondissement-of-paris",
    fr: "1er-arrondissement-de-paris",
  },
  {
    de: "2-arrondissement-von-paris",
    en: "2nd-arrondissement-of-paris",
    fr: "2e-arrondissement-de-paris",
  },
  {
    de: "3-arrondissement-von-paris",
    en: "3rd-arrondissement-of-paris",
    fr: "3e-arrondissement-de-paris",
  },
  {
    de: "4-arrondissement-von-paris",
    en: "4th-arrondissement-of-paris",
    fr: "4e-arrondissement-de-paris",
  },
  {
    de: "5-arrondissement-von-paris",
    en: "5th-arrondissement-of-paris",
    fr: "5e-arrondissement-de-paris",
  },
  {
    de: "6-arrondissement-von-paris",
    en: "6th-arrondissement-of-paris",
    fr: "6e-arrondissement-de-paris",
  },
  {
    de: "7-arrondissement-von-paris",
    en: "7th-arrondissement-of-paris",
    fr: "7e-arrondissement-de-paris",
  },
  {
    de: "8-arrondissement-von-paris",
    en: "8th-arrondissement-of-paris",
    fr: "8e-arrondissement-de-paris",
  },
  {
    de: "9-arrondissement-von-paris",
    en: "9th-arrondissement-of-paris",
    fr: "9e-arrondissement-de-paris",
  },
  {
    de: "10-arrondissement-von-paris",
    en: "10th-arrondissement-of-paris",
    fr: "10e-arrondissement-de-paris",
  },
  {
    de: "11-arrondissement-von-paris",
    en: "11th-arrondissement-of-paris",
    fr: "11e-arrondissement-de-paris",
  },
  {
    de: "12-arrondissement-von-paris",
    en: "12th-arrondissement-of-paris",
    fr: "12e-arrondissement-de-paris",
  },
  {
    de: "13-arrondissement-von-paris",
    en: "13th-arrondissement-of-paris",
    fr: "13e-arrondissement-de-paris",
  },
  {
    de: "14-arrondissement-von-paris",
    en: "14th-arrondissement-of-paris",
    fr: "14e-arrondissement-de-paris",
  },
  {
    de: "15-arrondissement-von-paris",
    en: "15th-arrondissement-of-paris",
    fr: "15e-arrondissement-de-paris",
  },
  {
    de: "16-arrondissement-von-paris",
    en: "16th-arrondissement-of-paris",
    fr: "16e-arrondissement-de-paris",
  },
  {
    de: "17-arrondissement-von-paris",
    en: "17th-arrondissement-of-paris",
    fr: "17e-arrondissement-de-paris",
  },
  {
    de: "18-arrondissement-von-paris",
    en: "18th-arrondissement-of-paris",
    fr: "18e-arrondissement-de-paris",
  },
  {
    de: "19-arrondissement-von-paris",
    en: "19th-arrondissement-of-paris",
    fr: "19e-arrondissement-de-paris",
  },
  {
    de: "20-arrondissement-von-paris",
    en: "20th-arrondissement-of-paris",
    fr: "20e-arrondissement-de-paris",
  },
];

// Maps region name to the version of the region name in the given language.
// If no mapping is found, returns the original region name.
export function mapRegionNameToSlugInLang(regionName, lang) {
  const normalizedRegionName = normalizeRegionName(regionName);
  return (
    LOCALISED_REGION_NAMES.find((reg) => {
      return (
        normalizedRegionName === reg.en ||
        normalizedRegionName === reg.fr ||
        normalizedRegionName === reg.de
      );
    })?.[lang] || regionName
  );
}

// Maps region name to lowercase English version
export function mapRegionNameToEnglishSlug(regionName) {
  return mapRegionNameToSlugInLang(regionName, "en");
}
