import React from "react";
import { hydrateRoot } from "react-dom/client";
import FeatureFlagContext from "../../contexts/FeatureFlagContext";
import { ShortlistContextProvider } from "../../contexts/ShortlistContext";
import bootstrapClient from "../bootstrap-client";
import { ApiContextProvider } from "../spa/context/ApiContext";
import { I18nContextProvider } from "../spa/context/I18nContext";
import ErrorPage from "./ErrorPage/ErrorPage";

// Rehydrate data that was sent down from the server
const LOCALE_DATA = JSON.parse(document.querySelector("#locales-hydrant").text);
const APP_DATA = JSON.parse(document.querySelector("#data-hydrant").text);

bootstrapClient(LOCALE_DATA, APP_DATA, onReady);

function onReady({ api, user, translationUrls, localizer, featureFlags }) {
  hydrateRoot(
    document.querySelector("#main"),
    <FeatureFlagContext.Provider value={featureFlags}>
      <ShortlistContextProvider user={user}>
        <I18nContextProvider t={localizer.t} lang={localizer.lang}>
          <ApiContextProvider api={api}>
            <ErrorPage {...APP_DATA} translationUrls={translationUrls} />
          </ApiContextProvider>
        </I18nContextProvider>
      </ShortlistContextProvider>
    </FeatureFlagContext.Provider>,
  );
}
