// TODO DEV-14774 Remove old validatePassword function
const validatePassword = ({ password, t }) => {
  if (!password) {
    return t("errors.required");
  }

  if (password?.length < 8) {
    return t("errors.mustBeMinLength", { expectation: 8 });
  }

  return null;
};

export default validatePassword;

export const getPasswordCriteria = (t) => [
  {
    label: t(
      "components.AuthComponent.SignupForm.inputs.password.minLengthRule",
    ),
    regex: /.{8,}/,
  },
  {
    label: t(
      "components.AuthComponent.SignupForm.inputs.password.uppercaseRule",
    ),
    regex: /[A-Z]/,
  },
  {
    label: t(
      "components.AuthComponent.SignupForm.inputs.password.lowercaseRule",
    ),
    regex: /[a-z]/,
  },
  {
    label: t("components.AuthComponent.SignupForm.inputs.password.numberRule"),
    regex: /\d/,
  },
  {
    label: t(
      "components.AuthComponent.SignupForm.inputs.password.specialCharRule",
    ),
    regex: /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/,
  },
  {
    label: t(
      "components.AuthComponent.SignupForm.inputs.password.latinOnlyRule",
    ),
    regex: /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]*$/,
  },
];

// TODO DEV-14774 Make this default export?
/** Validates if the given password matches the password validation criteria and returns a
 * translated error message if doesn't. If it matches all criteria, it returns null.
 */
export const validatePasswordIsSecure = ({ password, t }) => {
  if (!password) {
    return t("errors.required");
  }

  // Go through passwordCriteria and returns the first unmatched criteria
  const passwordCriteria = getPasswordCriteria(t);
  const unmatchedCriteria = passwordCriteria.find(
    ({ regex }) => !regex.test(password),
  );

  return unmatchedCriteria?.label ?? null;
};
